<template>
  <div v-if="isVisible">
    <div>
      <h3 class="px-2 px-sm-0">
        {{ this.$t('targets.previousTargetsTitle') }}
      </h3>
    </div>
    <question-element
      v-model="value"
      :readOnly="readOnly"
      :printView="printView"
      :preview="preview"
    />
    <v-dialog v-model="showTargetModal" max-width="1536">
      <v-card flat class="segmented targets__card">
        <v-card-text>
          <targets-table
            v-model="reviewableTargets"
            :type="targetTableTypes.REVIEW"
            :statusReadOnly="!currentUserIsRecipient"
            readOnly
            @saveTarget="saveTarget($event)"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text color="secondary" @click="showTargetModal = false">
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import questionElement from '@/components/forms/questionnaire/form-elements/question-element.vue';
import targetsTable from '@/components/feedbacks/targets/targets-table.vue';
import { formEnums } from '@/enums/forms.js';
import { targetEnums } from '@/enums/targets.js';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    printView: {
      type: Boolean,
      required: false,
      default: false
    },

    preview: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      targets: [],
      feedbacks: [],
      showTargetModal: false,
      targetTableTypes: targetEnums.tableTypes
    };
  },

  computed: {
    ...mapState('feedbacks', {
      feedbackData: 'currentFeedbackData'
    }),

    ...mapGetters({
      currentUser: 'currentUser',
      organization: 'currentOrganization'
    }),

    reviewableTargets() {
      const validTargets = this.targets.filter((x) => x.isFromFeedback);
      if (!validTargets.length) {
        return [];
      }

      const previousDates = this.feedbacks
        .filter((x) => x.archived)
        .filter((x) => x.processType === this.feedbackData.processType)
        .filter((x) => x.id != this.feedbackData.id)
        .map((x) => ({
          startDate: new Date(x.startDate),
          endDate: new Date(x.endDate)
        }))
        .sort((x, y) => x.endDate < y.endDate);

      if (!previousDates.length) {
        return [];
      }

      const lastDates = previousDates[0];
      return validTargets
        .filter((x) => new Date(x.crdt) >= lastDates.startDate)
        .filter((x) => new Date(x.crdt) <= lastDates.endDate);
    },

    isVisible() {
      return this.preview || this.reviewableTargets.length;
    },

    recipientId() {
      return this.feedbackData?.recipientId;
    },

    currentUserIsRecipient() {
      return this.recipientId == this.currentUser.id;
    }
  },

  watch: {
    async value() {
      await this.fetchData();
    },

    async recipientId() {
      this.targets = [];
      this.feedbacks = [];
      await this.fetchData();
    }
  },

  beforeDestroy() {
    window.openModal = null;
  },

  async mounted() {
    window.openModal = this.openModal.bind(this);
    await this.fetchData();
  },

  methods: {
    ...mapActions({
      getTargets: 'feedbacks/getTargets',
      saveTarget: 'feedbacks/saveTarget',
      getFeedbacks: 'feedbacks/listFeedbacksAsRecipient'
    }),

    async fetchData() {
      if (!this.value) {
        return;
      }

      if (this.recipientId) {
        if (!this.targets?.length) {
          var response = await this.getTargets(this.recipientId);
          if (response.success) {
            this.targets = response.result;
          }
        }

        if (!this.feedbacks?.length) {
          this.feedbacks = await this.getFeedbacks(this.recipientId);
        }

        this.reviewableTargets;
      }

      this.value.title = null;
      this.value.subtitle = this.$t('targets.previousTargetsSubtitle', {
        link: 'javascript:openModal()'
      });
      this.value.allowComment = true;
      this.value.commentFieldType = formEnums.textFieldType.MULTI_LINE;
    },

    openModal() {
      this.showTargetModal = true;
    }
  },

  components: {
    questionElement,
    targetsTable
  }
};
</script>

<style lang="scss" scoped></style>
